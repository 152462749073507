<template>
  <transition name="modal" v-if="hasBodySlot">
    <div class="modal-mask" >
      <div class="modal-wrapper">
        <div class="modal-container" v-bind:class="componentClass">

          <div class="modal-body">
            <slot name="body">
            </slot>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'VxModal',
  props: ['componentClass'],
  computed: {
    hasBodySlot() {
      return this.$slots.body
    },
  },
}
</script>

<style scoped lang="scss">


/* Modal */
.modal-mask {
  position: fixed;
  z-index: 100170;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 20px;
}

.modal-container-fit {
  width: fit-content;
}

.modal-container-7 {
  width: 70%;
}

.modal-container {
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.modal-body {
  z-index: 9999;
  margin: 20px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.theme-dark {
  .modal-container {

    margin: 0px auto;
    padding: 20px 30px;
    background-color: #262c49;
    border-radius: 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
}
</style>
